import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

export const tagRecipe = defineMultiStyleConfig({
  baseStyle: definePartsStyle(({ colorScheme }) => {
    return {
      container: {
        bg: `${colorScheme}.500`,
        px: '4',
        py: '1',
        borderRadius: 'lg',
      },
      label: {
        color: 'white',
      },
    };
  }),
  sizes: {
    sm: {
      label: {
        fontSize: 'xxs',
      },
    },
    md: {
      label: {
        fontSize: 'md',
      },
    },
  },
  variants: {
    promo: defineStyle(() => ({
      container: {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        pl: '6px',
        pr: '8px',
        py: '2px',
      },
      label: { fontSize: { base: 'sm', md: 'lg' } },
    })),
    vertical: defineStyle(() => ({
      container: {
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        px: '1',
        py: '2',
      },
      label: { fontSize: { base: 'sm', md: 'lg' } },
    })),
  },
  defaultProps: {
    colorScheme: 'primary',
  },
});
