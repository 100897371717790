import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const textareaRecipe = defineStyleConfig({
  baseStyle: defineStyle(({ isInvalid }) => ({
    resize: 'none',
    border: '1px solid',
    borderColor: isInvalid ? 'error.500' : 'gray.600',
    boxShadow: 'none',
    fontSize: { base: 'sm', md: 'md' },
    _focusVisible: {
      boxShadow: 'none',
      borderColor: isInvalid ? 'error.500' : 'text',
    },
  })),
  sizes: {
    md: {
      field: {
        h: '40px',
        borderRadius: 'md',
        px: '4',
        py: '2.5',
        my: '5px',
      },
    },
    lg: {
      field: {
        h: '50px',
        borderRadius: 'sm',
        px: '4',
        py: '4',
        my: '9px',
      },
    },
    xl: {
      field: {
        h: '76px',
        borderRadius: 'sm',
        px: '6',
        py: '6',
        my: '3',
      },
    },
  },
  variants: {
    outline: defineStyle(({ isInvalid }) => ({
      color: 'gray.800',
      borderColor: 'gray.600',
      _placeholder: {
        color: 'gray.700',
      },
      _hover: {
        borderColor: 'gray.600',
      },
      _focusVisible: {
        boxShadow: 'none !important',
        borderColor: isInvalid ? 'error.500' : 'text',
        _hover: {
          borderColor: isInvalid ? 'error.500' : 'text',
        },
      },
    })),
    filled: defineStyle(({ isInvalid }) => ({
      color: 'gray.700',
      bg: 'gray.200',
      border: '1.2px solid',
      _hover: {
        bg: 'gray.200',
      },
      _focusVisible: {
        boxShadow: 'none !important',
        borderColor: isInvalid ? 'error.500' : 'gray.800',
        _hover: {
          backgroundColor: 'gray.200',
        },
      },
      _placeholder: {
        color: 'gray.600',
      },
    })),
  },
  defaultProps: {
    size: 'md',
    variant: 'filled',
    colorScheme: 'white',
  },
});

export default textareaRecipe;
