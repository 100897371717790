const LOGIN = 'login';
const SIGN_IN_OTP = '/sign-in-otp';
const SIGN_IN_VERIFY_OTP = '/verify-sign-in-otp';
const FORGET_PASSWORD_OTP = '/forget-password-otp';
const FORGET_PASSWORD_VERIFY_OTP = '/verify-forget-password-otp';
const SET_PASSWORD = 'forget-password';
const CHANGE_PASSWORD = 'change-password';
const SIGN_UP_OTP = '/sign-up-otp';
const SIGN_UP_VERIFY_OTP = '/verify-sign-up-otp';
const CMS = 'cms';
const USER = '/user';
const SIGN_UP = 'users';
const USER_INQUIRIES = 'user-inquiries';
const USER_RECRUITMENT_RATES = 'user-recruitment-rates';
const COUNTRIES = 'countries';
const PROVINCES = 'provinces';
const CITIES = 'cities';
const DISTRICTS = 'districts';
const LOGOUT = 'logout';
const PROFILE = 'profile';
const COMMISSION = 'commission';
const USER_TOTAL_PAID = 'user-total-paid';
const VIOLATION = 'violation';
const USER_VIOLATIONS = 'user-violations';
const TREE = 'tree';
const USER_COMMISSIONS = 'user-commissions';
const ORDER = 'order';
const ORDER_STATISTICS = 'order-statistics';
const SLIDERS = 'sliders';
const JOB_POSITIONS = 'job-positions';
const CRM = 'crm';
const TICKETS = 'tickets';
const EXECUTIVE_TEAMS = 'executive-teams';
const STATIC_PAGES = 'static-pages';
const PROMOTION_BANNER = 'promotion-banners';
const NEWS = 'news';
const JOB_POSITION_RESUMES = 'job-position-resumes';
const COMMENTS = 'comments';
const NEWS_COMMENTS = 'news-comments';
const INQUIRY_USER_NEWS_RATES = 'inquiry-user-news-rates';
const NEWS_RATES = 'news-rates';
const WAREHOUSE = 'warehouse';
const SHIPMENT = 'shipment';
const COMPANY_INFORMATION = 'company-information';
const CONTACT_US = 'contact-us';
const COMPLAINTS = 'complaints';
const PLANS = 'plans';
const SOCIAL_NETWORKS = 'social-networks';
const CATEGORIES = 'categories';
const PRODUCT = 'product';
const PRODUCT_GROUPS = 'product-groups';
const PRODUCT_GROUP_INVENTORIES = 'product-group-inventories';
const VERIFY_SMS = 'verify-sms';
const HELLO_BARS = 'hello-bars';
const INQUIRY_USER_VOTE = 'inquiry-user-vote-products';
const NPS = 'nps';
const VOTE_QUESTIONS = 'questions';
const VOTE_PRODUCT = 'vote-products';
const PRODUCT_COMMENTS = 'product-comments';
const WELCOME_MODALS = 'welcome-modals';
const WELCOME_MODALS_LOGS = 'welcome-modal-logs';
const DISCOUNT_PRODUCTS = 'available-discount-products';
const BANK_ACCOUNT = 'bank-accounts';
const BRANDS = 'brands';
const PRODUCT_FAVORITES = 'product-favorites';
const UPLOAD_AVATAR = 'user-avatars';
const USER_RANK_TIMELINE = 'user-rank-timeline';
const DEPARTMENTS = 'departments';
const PROBLEMS = 'problems';
const ORDERS = 'orders';
const COMPLEMENTARY_PRODUCTS = 'complementary-products';
const USER_SEEN_PRODUCTS = 'user-seen-products';
const PRODUCT_FAQS = 'faqs';
const DEDICATED_LINK = 'user-exclusive-links';
const USER_CALCULATIONS = 'user-calculations';
const CALC_TAX = 'calc-tax';
const USER_ADDRESSES = 'user-addresses';
const USER_DEFAULT_ADDRESSES = 'user-default-addresses';
const MESSAGES = 'messages';
const CLOSE = 'close';
const CLIENT_SCORE = 'client-score';
const ORDER_DELIVERY_SHIFT = 'order-delivery-shifts';
const CHANGE_DELIVERY_SHIFT = 'change-delivery-shifts';
const CHANGE_RECIPIENTS = 'change-recipients';
const LOGISTIC_DELIVERIES = 'logistic-deliveries';
const IN_PERSON_DELIVERIES = 'in-person-deliveries';
const CONFIRM_RESEND = 'confirm-resend';
const DASHBOARD_TICKETS = 'dashboard-tickets';
const BANKS = 'banks';
const HEIRS = 'user-heirs';
const UPLOAD_IDENTITY_FILE = 'upload-identity-file';
const VALIDATE_BASKET = 'validate-basket';
const BECOME_AGENTS = 'become-agents';
const FILES = 'files';
const PAYMENT = 'payment';
const PAYMENTS = 'payments';
const VERIFICATION = 'verification';
const USER_VERIFICATION = 'user-verifications';
const ORDER_ROLLBACKS = 'order-rollbacks';
const CALC_USER_RETAIL_PERCENTAGES = 'calc-user-retail-percentages';
const USER_METADATA = 'user-metadata';
const RETAIL = 'calc-user-retail-percentages';
const USER_REFERRAL_LINKS = 'user-referral-links';
const LPRS = 'lprs';
const CONSULTATION_REQUEST = 'consultation-requests';
const EXAMS = 'exams';
const ORDER_PRODUCTS = 'order-products';
const EXAMS_ORDER_PRODUCTS = 'exam-order-products';
const APPLY_DISCOUNT_PRODUCT_CODES = 'apply-discount-product-codes';
const EXAM_STATISTICS = 'exam-statistics';
const DISCOUNT_BASKET_PRODUCTS = 'discount-basket-products';
const NOTIFICATION = 'notification';
const POPUPS = 'popups';
const SEEN = 'seen';
const AUTH_TOKENS = 'auth-tokens';
const TAX = 'tax';
const UserEconomicActivities = 'user-economic-activities';
const FAQ_CATEGORIES = 'faq-categories';
const FAQ = 'faq';
const TICKET_SUGGEST_QUESTIONS = 'ticket-suggest-questions';
const SET_USE = 'set-use';

const urls = {
  signInOtp: SIGN_IN_OTP,
  signInVerifyOtp: SIGN_IN_VERIFY_OTP,
  forgetPasswordOtp: FORGET_PASSWORD_OTP,
  forgetPasswordVerifyOtp: FORGET_PASSWORD_VERIFY_OTP,
  setPassword: `${USER}/${SET_PASSWORD}`,
  signUpOtp: SIGN_UP_OTP,
  signUpVerifyOtp: SIGN_UP_VERIFY_OTP,
  signUp: `${USER}/${SIGN_UP}`,
  login: LOGIN,
  logout: LOGOUT,
  profile: `${PROFILE}/${PROFILE}`,
  userInquiries: `${USER}/${USER_INQUIRIES}`,
  countries: `${USER}/${COUNTRIES}`,
  provinces: `${USER}/${PROVINCES}`,
  userTotalPaid: `${COMMISSION}/${USER_TOTAL_PAID}`,
  userViolations: `${VIOLATION}/${USER_VIOLATIONS}`,
  userCommissions: `${TREE}/${USER_COMMISSIONS}`,
  userOrderStatistics: `${ORDER}/${ORDER_STATISTICS}`,
  userRecruitmentRates: `${USER}/${USER_RECRUITMENT_RATES}`,
  jobPositions: `${CMS}/${JOB_POSITIONS}`,
  jobPositionSingle: (code: string): string =>
    `${CMS}/${JOB_POSITIONS}/${code}`,
  tickets: `${CRM}/${TICKETS}`,
  dashboardTickets: `${CRM}/${DASHBOARD_TICKETS}`,
  executiveTeam: `${CMS}/${EXECUTIVE_TEAMS}`,
  staticPages: `${CMS}/${STATIC_PAGES}`,
  cities: (province_id: string): string =>
    `${USER}/${PROVINCES}/${province_id}/${CITIES}`,
  districts: (city_id: string): string =>
    `${USER}/${CITIES}/${city_id}/${DISTRICTS}`,
  sliders: `${CMS}/${SLIDERS}`,
  categories: `${PRODUCT}/${CATEGORIES}`,
  category: (code: number): string => `${PRODUCT}/${CATEGORIES}/${code}`,
  productGroups: `${PRODUCT}/${PRODUCT_GROUPS}`,
  productGroup: (code: number): string =>
    `${PRODUCT}/${PRODUCT_GROUPS}/${code}`,
  promotionBanner: `${CMS}/${PROMOTION_BANNER}`,
  news: `${CMS}/${NEWS}`,
  jobPositionResumes: `${CMS}/${JOB_POSITION_RESUMES}`,
  newSingle: (code: string): string => `${CMS}/${NEWS}/${code}`,
  getNewComments: (code: string): string =>
    `${CMS}/${NEWS}/${code}/${COMMENTS}`,
  getRateNew: `${CMS}/${INQUIRY_USER_NEWS_RATES}`,
  postNewComment: `${CMS}/${NEWS_COMMENTS}`,
  postRateNew: `${CMS}/${NEWS_RATES}`,
  contactUsInformation: `${CMS}/${COMPANY_INFORMATION}`,
  contactUs: `${CMS}/${CONTACT_US}`,
  complaints: `${CMS}/${COMPLAINTS}`,
  updatePassword: `${USER}/${CHANGE_PASSWORD}`,
  plans: `${CMS}/${PLANS}`,
  socialNetworks: `${CMS}/${SOCIAL_NETWORKS}`,
  updatePersonalInformation: `${PROFILE}/${PROFILE}`,
  updateFurtherInformation: `${PROFILE}/${PROFILE}`,
  submitOtpCode: `${USER}/${VERIFY_SMS}`,
  uploadIdentityFile: `${USER}/${UPLOAD_IDENTITY_FILE}`,
  helloBars: `${CMS}/${HELLO_BARS}`,
  inquiryUserVote: `${NPS}/${INQUIRY_USER_VOTE}`,
  voteQuestions: `${NPS}/${VOTE_QUESTIONS}`,
  voteProduct: `${NPS}/${VOTE_PRODUCT}`,
  productComments: `${PRODUCT}/${PRODUCT_COMMENTS}`,
  productGroupComments: (code: string): string =>
    `${PRODUCT}/${PRODUCT_GROUPS}/${code}/${PRODUCT_COMMENTS}`,
  productGroupFaqs: (code: string): string =>
    `${PRODUCT}/${PRODUCT_GROUPS}/${code}/${PRODUCT_FAQS}`,
  welcomeModal: `${CMS}/${WELCOME_MODALS}`,
  welcomeModalLogs: `${CMS}/${WELCOME_MODALS_LOGS}`,
  discountProducts: `${PRODUCT}/${DISCOUNT_PRODUCTS}`,
  bankAccount: `${USER}/${BANK_ACCOUNT}`,
  updateBankInformation: `${USER}/${BANK_ACCOUNT}`,
  brands: `${PRODUCT}/${BRANDS}`,
  viewBrand: (code: string): string => `${PRODUCT}/${BRANDS}/${code}`,
  productFavorites: `${PRODUCT}/${PRODUCT_FAVORITES}`,
  deleteFavorite: (id: string): string =>
    `${PRODUCT}/${PRODUCT_FAVORITES}/${id}`,
  uploadAvatar: `${USER}/${UPLOAD_AVATAR}`,
  userRankTimeline: `${COMMISSION}/${USER_RANK_TIMELINE}`,
  problems: `${CRM}/${PROBLEMS}`,
  departments: `${CRM}/${DEPARTMENTS}`,
  orders: `${ORDER}/${ORDERS}`,
  order: (id: string): string => `${ORDER}/${ORDERS}/${id}`,
  orderDetail: `${ORDER}/${ORDERS}`,
  rollbacks: `${ORDER}/${ORDER_ROLLBACKS}`,
  rollback: (code: string): string => `${ORDER}/${ORDER_ROLLBACKS}/${code}`,
  getExclusiveLink: `${USER}/${DEDICATED_LINK}`,
  complementaryProducts: (code: number): string =>
    `${PRODUCT}/${PRODUCT_GROUPS}/${code}/${COMPLEMENTARY_PRODUCTS}`,
  userSeenProducts: `${PRODUCT}/${USER_SEEN_PRODUCTS}`,
  deleteLike: (id: string): string => `${PRODUCT}/${PRODUCT_FAVORITES}/${id}`,
  userCalculations: `${COMMISSION}/${USER_CALCULATIONS}`,
  calcTax: `${COMMISSION}/${CALC_TAX}`,
  promotion: (code: string): string =>
    `${PRODUCT}/${DISCOUNT_PRODUCTS}/${code}`,
  productGroupInventories: `${PRODUCT}/${PRODUCT_GROUP_INVENTORIES}`,
  addresses: `${USER}/${USER_ADDRESSES}`,
  getDefaultAddresses: `${USER}/${USER_DEFAULT_ADDRESSES}`,
  createAddress: `${USER}/${USER_ADDRESSES}`,
  updateAddress: (id: string): string => `${USER}/${USER_ADDRESSES}/${id}`,
  newTicketMessages: (id: string): string =>
    `${CRM}/${TICKETS}/${id}/${MESSAGES}`,
  closeTicket: (id: string): string => `${CRM}/${TICKETS}/${id}/${CLOSE}`,
  clientScore: (id: string): string =>
    `${CRM}/${TICKETS}/${id}/${CLIENT_SCORE}`,
  validateBasket: `${PRODUCT}/${VALIDATE_BASKET}`,
  resendOrder: (id: string): string =>
    `${ORDER}/${ORDERS}/${id}/${CONFIRM_RESEND}`,
  deliveryShift: `${WAREHOUSE}/${ORDER_DELIVERY_SHIFT}`,
  updateDeliveryShift: (id: string): string =>
    `${ORDER}/${ORDERS}/${id}/${CHANGE_DELIVERY_SHIFT}`,
  changeReceivers: (id: string): string =>
    `${ORDER}/${ORDERS}/${id}/${CHANGE_RECIPIENTS}`,
  logisticDelivery: `${SHIPMENT}/${LOGISTIC_DELIVERIES}`,
  inPersonDeliveries: `${SHIPMENT}/${IN_PERSON_DELIVERIES}`,
  banks: `${PAYMENT}/${BANKS}`,
  userHeir: `${USER}/${HEIRS}`,
  updateUserHeir: `${USER}/${HEIRS}`,
  becomeAgent: `${USER}/${BECOME_AGENTS}`,
  uploadFile: `${CRM}/${FILES}`,
  gateways: `${PAYMENT}/${PAYMENTS}`,
  emtaVerify: `${VERIFICATION}/${USER_VERIFICATION}`,
  calcUserRetailPercentages: `${ORDER}/${CALC_USER_RETAIL_PERCENTAGES}`,
  userMetadata: `${USER}/${USER_METADATA}`,
  retail: `${ORDER}/${RETAIL}`,
  lprsLinks: `${LPRS}/${USER_REFERRAL_LINKS}`,
  createLink: `${LPRS}/${USER_REFERRAL_LINKS}`,
  consultationsManage: `${LPRS}/${CONSULTATION_REQUEST}`,
  consultationChecked: (id: string): string =>
    `${LPRS}/${CONSULTATION_REQUEST}/${id}`,
  exams: `${LPRS}/${EXAMS}`,
  examsId: (id: string): string => `${LPRS}/${EXAMS}/${id}`,
  addExamProduct: `${LPRS}/${EXAMS_ORDER_PRODUCTS}`,
  updateExamProduct: ({
    idExam,
    idProduct,
  }: {
    idExam: string;
    idProduct: string;
  }): string => `${LPRS}/${EXAMS}/${idExam}/${ORDER_PRODUCTS}/${idProduct}`,
  discountProductCode: `${PRODUCT}/${APPLY_DISCOUNT_PRODUCT_CODES}`,
  examStatistics: `${LPRS}/${EXAM_STATISTICS}`,
  discountBasketProducts: `${PRODUCT}/${DISCOUNT_BASKET_PRODUCTS}`,
  notifications: `${NOTIFICATION}/${POPUPS}`,
  seenPopups: (id: string): string => `${NOTIFICATION}/${POPUPS}/${id}/${SEEN}`,
  lprsAuthToken: `${LPRS}/${AUTH_TOKENS}`,
  userEconomicActivities: `${TAX}/${UserEconomicActivities}`,
  faqCategories: `${CMS}/${FAQ_CATEGORIES}`,
  faqs: `${CMS}/${FAQ}`,
  findSimilarQuestion: `${FAQ}/${TICKET_SUGGEST_QUESTIONS}`,
  gotTicketAnswer: (id: string): string =>
    `${FAQ}/${TICKET_SUGGEST_QUESTIONS}/${id}/${SET_USE}`,
};

export default urls;
