import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const textRecipe = defineStyleConfig({
  baseStyle: defineStyle(() => ({
    color: 'text',
    fontSize: { base: '14px', md: '16px' },
  })),
  defaultProps: {
    size: 'md',
  },
});

export default textRecipe;
