'use client';

import type {
  HelloBars,
  SystemListenerProps,
  UserListenerProps,
} from '@/types';

import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import io, { type Socket } from 'socket.io-client';
import { useShallow } from 'zustand/react/shallow';

import '@/i18n';

import { ChakraProvider, HelloBar } from '@/components';
import useIsAdmin from '@/hooks/store/useIsAdmin';
import { theme } from '@/theme';
import { api, createQueryClient, setDayjsJalaliCalendar } from '@/utils';

const WelcomeModal = dynamic(() => import('@/components/ui/WelcomeModal'), {
  ssr: false,
});

const EmploymentInformationModal = dynamic(
  () => import('@/components/ui/EmploymentInformationModal'),
  {
    ssr: false,
  }
);

const MarketingInformationModal = dynamic(
  () => import('@/components/ui/MarketingInformationModal'),
  {
    ssr: false,
  }
);

const DelaChat = dynamic(() => import('@/components/ui/DelaChat'), {
  ssr: false,
});

const Provider: React.FC<{
  token: string | undefined;
  children: React.ReactNode;
  helloBar: HelloBars | null;
}> = ({ children, token, helloBar }) => {
  const pathname = usePathname();

  const hasHelloBar =
    !pathname.includes('identity') && !pathname.includes('auth');

  setDayjsJalaliCalendar();

  api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });
  const [isOpen, setIsOpen] = React.useState(true);
  const [isOpenMarketingModal, setIsOpenMarketingModal] = React.useState(true);

  const onClose = () => {
    setIsOpen(false);
  };
  const [queryClient] = React.useState(() => createQueryClient({}));

  const isAdminUser = useIsAdmin(useShallow((state) => state.isAdmin));
  const availableCustomer = token && !isAdminUser;

  const {
    isOpen: eventIsOpen,
    onOpen,
    onClose: eventOnClose,
  } = useDisclosure();
  const [event, setEvent] = React.useState<{
    title: string;
    payload: string;
    icon: string;
    link: string;
    message_category: string;
  } | null>(null);

  const [socket, setSocket] = React.useState<Socket | null>(null);
  React.useEffect((): any => {
    if (!socket && process.env.NEXT_PUBLIC_LIATEAM_SOCKET) {
      setSocket(() =>
        io(process.env.NEXT_PUBLIC_LIATEAM_SOCKET || '', {
          extraHeaders: {
            Authorization: token || '',
            realm: 'shop',
          },
          forceNew: true,
          reconnectionAttempts: 3,
          timeout: 2000,
        })
      );
    }
    return () => socket?.close();
  }, []);

  React.useEffect(() => {
    if (socket === null) {
      return;
    }
    if (socket) {
      socket.on('USER', (userEvent: UserListenerProps) => {
        setEvent(userEvent);
        onOpen();
      });

      socket.on('SYSTEM', (newEvent: SystemListenerProps) => {
        if (newEvent.command === 'REFRESH') {
          window.location.reload();
        }
      });
    }
    if (!token) {
      socket.disconnect();
    }
  }, [socket]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme} cssVarsRoot="body">
        {helloBar?.id && hasHelloBar && <HelloBar data={helloBar} />}
        {availableCustomer && (
          <WelcomeModal isOpen={isOpen} onClose={onClose} />
        )}
        {availableCustomer && !pathname.includes('auth') && (
          <MarketingInformationModal
            isOpen={isOpenMarketingModal}
            onClose={setIsOpenMarketingModal}
          />
        )}
        {availableCustomer && !pathname.includes('auth') && (
          <EmploymentInformationModal />
        )}
        {token && (
          <DelaChat isOpen={eventIsOpen} onClose={eventOnClose} event={event} />
        )}
        {children}
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default Provider;
