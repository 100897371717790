import type { ProfileResponse } from '@/types';

import { redirect, usePathname } from 'next/navigation';

import { routes } from '@/statics';

const useCheckAccess = (profile: ProfileResponse | undefined): void => {
  const isAgent = profile?.user_type === 'agent';
  const isSuspended = profile?.is_suspended;
  const hasRollback = profile?.has_rollback;

  const pathname = usePathname();

  const hasToCheckRoutes = {
    [routes.nextMonthRetailDiscount.url]: isAgent && !isSuspended,
    [routes.rollbacks.url]: hasRollback,
    [routes.manageLprs.url]: isAgent,
    [routes.commission.url]: isAgent && !isSuspended,
  };

  const needToCheckAccess = Object.keys(hasToCheckRoutes).includes(pathname);

  if (needToCheckAccess && !hasToCheckRoutes[pathname]) {
    redirect(routes.dashboard.url);
  }
};

export default useCheckAccess;
