import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type IsAdminType = {
  isAdmin: boolean;
  toggleUserType: (isAdmin: boolean) => void;
};

const useIsAdmin = create<IsAdminType>()(
  devtools(
    persist(
      immer((set) => ({
        isAdmin: false,
        toggleUserType: (isAdmin: boolean) => set(() => ({ isAdmin: isAdmin })),
      })),
      {
        name: 'is-admin',
      }
    ),
    { enabled: process.env.NODE_ENV === 'development' }
  )
);

export default useIsAdmin;
