import type { CreateToastFnReturn, UseToastOptions } from '@chakra-ui/react';
// eslint-disable-next-line no-duplicate-imports
import { Box, createStandaloneToast, Spinner } from '@chakra-ui/react';

const toast = createStandaloneToast();

const standaloneToast = (
  options: UseToastOptions
): ReturnType<CreateToastFnReturn> => {
  const { status } = options;

  let newOptions = { ...options };

  if (status === 'loading') {
    newOptions = {
      ...options,
      icon: (
        <Box width="23px" height="23px">
          <Spinner color="success.500" />
        </Box>
      ),
    };
  }

  return toast.toast(newOptions);
};

export default standaloneToast;
