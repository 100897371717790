import { defineStyleConfig } from '@chakra-ui/react';

export const formLabelRecipe = defineStyleConfig({
  baseStyle: {
    fontWeight: '400',
    color: 'gray.700',
    mb: 0,
    _invalid: {
      color: 'error.500',
    },
  },
});
