import axios, {
  type Axios,
  type AxiosPromise,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';

import { deleteCookie } from './cookieManager';
import { deleteLocalStorage, getLocalStorage } from './localStorageManager';
import standaloneToast from './standaloneToast';

import { urls } from '@/statics';

export type CustomAxiosInstance = {
  (config: AxiosRequestConfig): AxiosPromise;
  (url: string, config?: AxiosRequestConfig): AxiosPromise;
} & Axios;
const api: CustomAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

type Response = {
  data: Record<string, string>;
  message: string;
  success: boolean;
  code: number;
};

api.interceptors.request.use((config) => {
  if (typeof window !== 'undefined') {
    const localStorageToken = getLocalStorage({ name: 'lia-token' });
    if (localStorageToken) {
      config.headers.Authorization = localStorageToken;
    } else {
      delete config.headers.Authorization;
    }
  }
  return config;
});

api.interceptors.response.use(
  (response: AxiosResponse<Response>): AxiosResponse['data'] => {
    return response.data.data;
  },
  async (error) => {
    const { response, config } = error as {
      response: { status: number; data: { message: string } };
      config: { url: string };
    };
    if (response) {
      const { status, data } = response ?? {
        status: null,
      };

      if (![urls.userInquiries].includes(String(config.url))) {
        standaloneToast({
          status: 'error',
          title: data.message,
        });
      }
      if (status === 401) {
        deleteLocalStorage({ name: 'lia-token' });
        await deleteCookie({ name: 'lia-token' });
        if (window.location.pathname === '/') {
          window.location.reload();
        } else {
          window.location.replace(window.location.origin || '/');
        }
        return Promise.reject(error as unknown as Error);
      }
      if (status === 503) {
        window.location.replace('serviceUnavailable');
        return Promise.reject(error as unknown as Error);
      }
    }

    return Promise.reject(error as unknown as Error);
  }
);

export default api;
