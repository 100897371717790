import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

export const modalRecipe = defineMultiStyleConfig({
  baseStyle: definePartsStyle(() => {
    return {
      body: {
        paddingInlineStart: 4,
        paddingInlineEnd: 4,
      },
      header: {
        paddingInlineStart: 4,
        paddingInlineEnd: 4,
      },
      dialog: {
        maxWidth: { base: 'full', md: '870px' },
        w: { base: 'full', md: '870px' },
      },
    };
  }),
});
