'use client';

import React from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';

import { useBasketStore, useProductGroupsByCode, useProfile } from '@/hooks';
import useCheckAccess from '@/hooks/useCheckAccess';
import { setCookie } from '@/utils';

const SyncWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const nextSearchParams = new URLSearchParams(searchParams.toString());
  const { data: profile } = useProfile();
  const [products, userType, add, remove] = useBasketStore(
    useShallow((state) => [
      state.products,
      state.userType,
      state.add,
      state.remove,
    ])
  );

  useCheckAccess(profile);

  const basketProductIds =
    searchParams.get('basket_product_ids') &&
    searchParams.get('basket_product_ids') !== ''
      ? searchParams
          .get('basket_product_ids')
          ?.split(',')
          .map((id) => Number(id))
      : [];

  const { refetch: fetchUrlProducts } = useProductGroupsByCode({
    codes: basketProductIds || [],
  });

  const handleQueryStrings = async () => {
    const userId = searchParams.get('user_id') || '';

    if (userId) {
      await setCookie({ name: 'user_id', value: userId });
      nextSearchParams.delete('user_id');
    }
    if (basketProductIds && basketProductIds.length > 0) {
      const result = await fetchUrlProducts();
      result.data?.list?.map((product) => {
        add({ product, count: 1 });
      });
      nextSearchParams.delete('basket_product_ids');
    }
    if ((basketProductIds && basketProductIds.length > 0) || userId) {
      router.replace(`${pathname}?${nextSearchParams.toString()}`);
    }
  };

  React.useEffect(() => {
    void handleQueryStrings();
  }, []);

  const { refetch: fetchNewProducts } = useProductGroupsByCode({
    codes: products.map((product) => Number(product.code)),
  });

  const [previousUserType, setPreviousUserType] = React.useState(userType);

  React.useEffect(() => {
    if (profile) {
      useBasketStore.setState({ userType: profile.user_type });
    } else {
      useBasketStore.setState({ userType: null });
    }
  }, [profile]);

  React.useEffect(() => {
    if (previousUserType !== userType && products.length > 0) {
      void fetchNewProducts().then((result) =>
        result.data?.list?.map((product) => {
          const quantity = products.find(
            (item) => item.code === product.code
          )?.quantity;
          remove({ code: product.code });
          add({ product, count: quantity });
        })
      );
    }
    setPreviousUserType(userType);
  }, [userType]);

  return <>{children}</>;
};

export default SyncWrapper;
