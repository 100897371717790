import { stepperAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(stepperAnatomy.keys);

export const stepperRecipe = defineMultiStyleConfig({
  baseStyle: definePartsStyle(({ colorScheme }) => ({
    stepper: {
      '&[data-orientation=horizontal]': {
        gap: '0',
      },
    },
    number: {
      fontWeight: 'bold',
      fontSize: { base: '22px', lg: '33px' },
      color: 'gray.700',
    },
    step: {
      gap: '0',
    },
    separator: {
      '&[data-orientation=horizontal]': {
        height: { base: '3px', lg: '1.5' },
        marginInlineStart: '0',
        backgroundColor: 'gray.400',
        '&[data-status=complete]': {
          backgroundColor: `${colorScheme}.600`,
        },
      },
    },
    indicator: {
      width: { base: '44px', lg: '102px' },
      height: { base: '44px', lg: '102px' },
      position: 'relative',
      '&[data-status=incomplete]': {
        bg: 'gray.300',
        borderWidth: { base: '3px', lg: '4px' },
        borderColor: 'white',
      },
      '&[data-status=active], &[data-status=complete]': {
        bg: `${colorScheme}.100`,
        borderColor: `${colorScheme}.600`,
        borderWidth: { base: '3px', lg: '9px' },
      },
    },
    title: {
      fontSize: { base: 'xs', lg: 'lg' },
      color: 'text',
      position: 'absolute',
      top: { base: '12', lg: '28' },
      textAlign: 'center',
      width: { base: '150%', lg: '100%', xl: 'max-content' },
      '&[data-status=incomplete]': {
        color: 'gray.700',
      },
    },
  })),
  defaultProps: {
    colorScheme: 'secondary',
  },
});
