import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

export const alertRecipe = defineMultiStyleConfig({
  baseStyle: definePartsStyle((props) => {
    return {
      title: {
        fontSize: 'md',
        borderStyle: 'solid',
        alignItems: 'center',
        display: 'flex',
        color: 'text',
      },
      description: {
        minH: '47px',
        fontSize: 'md',
        borderStyle: 'solid',
        alignItems: 'center',
        display: 'flex',
        color: 'text',
      },
      container: {
        borderRadius: 'xs',
        bg: `${props.status}.300`,
      },
      icon: {
        marginInlineEnd: '4',
        color: `${props.status}.500`,
        w: 6,
      },
    };
  }),
});
