import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const inputRecipe = {
  ...defineMultiStyleConfig({
    baseStyle: definePartsStyle(({ isInvalid }) => ({
      field: {
        border: '1px solid',
        borderColor: isInvalid ? 'error.500' : 'gray.600',
        boxShadow: 'none',
        fontSize: { base: 'sm', md: 'md' },
        _focusVisible: {
          borderWidth: '1.2px',
          borderColor: isInvalid ? 'error.500' : 'text',
        },
      },
    })),
    sizes: {
      md: {
        field: {
          h: '40px',
          borderRadius: 'md',
          px: '4',
          py: '2.5',
          my: '5px',
        },
      },
      lg: {
        field: {
          h: '50px',
          borderRadius: 'sm',
          px: '4',
          py: '4',
          my: '9px',
        },
      },
      xl: {
        field: {
          h: '76px',
          borderRadius: 'sm',
          px: '6',
          py: '6',
          my: '3',
        },
      },
    },
    variants: {
      outline: definePartsStyle(({ isInvalid }) => ({
        field: {
          color: 'gray.800',
          bg: 'white',
          border: '1px solid',
          borderColor: 'gray.600',
          _placeholder: {
            color: 'gray.700',
          },
          _hover: {
            borderColor: 'gray.600',
          },
          _focusVisible: {
            border: '1.2px solid',
            borderColor: isInvalid ? 'error.500' : 'text',
            boxShadow: 'none',
            _hover: {
              borderColor: isInvalid ? 'error.500' : 'text',
            },
          },
        },
      })),
      filled: definePartsStyle(({ isInvalid }) => ({
        field: {
          color: 'gray.700',
          bg: 'gray.200',
          border: '1.2px solid',
          _hover: {
            bg: 'gray.200',
          },
          _focusVisible: {
            bg: 'gray.200',
            borderColor: isInvalid ? 'error.500' : 'gray.800',
          },
          _placeholder: {
            color: 'gray.600',
          },
        },
      })),
    },
    defaultProps: {
      size: 'md',
      variant: 'filled',
      colorScheme: 'white',
    },
  }),
};

export default inputRecipe;
