import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  formErrorAnatomy.keys
);

export const formErrorRecipe = defineMultiStyleConfig({
  baseStyle: {
    text: {
      color: 'text',
      fontSize: 'xs',
      bg: 'error.400',
      maxW: 'fit-content',
      py: 2,
      px: 2.5,
      borderRadius: '2xs',
      mt: 0,
    },
    icon: {
      w: 4,
      h: 4,
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});
