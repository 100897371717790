import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  formAnatomy.keys
);

export const formRecipe = defineMultiStyleConfig({
  baseStyle: {
    helperText: {
      mt: 0,
    },
  },
});
