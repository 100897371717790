import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

export const checkboxRecipe = defineMultiStyleConfig({
  baseStyle: definePartsStyle(({ colorScheme }) => {
    return {
      control: {
        borderRadius: '3xs',
        color: 'white',
        borderColor: 'gray.600',
        _checked: {
          borderColor: `${colorScheme}.500`,
          fontSize: '24px',

          _disabled: {
            borderColor: 'gray.300',
            background: 'gray.300',
            color: 'white',
          },
        },
        _disabled: {
          borderColor: 'gray.400',
          background: 'white',
        },
      },
      label: {
        marginRight: 2.5,
        _disabled: {
          opacity: 1,
        },
      },
    };
  }),
  sizes: {
    md: {
      label: {
        fontSize: 'sm',
      },
    },
  },
  variants: {},
  defaultProps: {
    colorScheme: 'primary',
  },
});
