import type { ThemeExtender } from './types';

import { extendTheme } from '@chakra-ui/react';

import { fonts } from './fonts';
import {
  alertRecipe,
  buttonRecipe,
  cardRecipe,
  checkboxRecipe,
  containerRecipe,
  formErrorRecipe,
  formLabelRecipe,
  formRecipe,
  inputRecipe,
  linkRecipe,
  modalRecipe,
  radioRecipe,
  selectRecipe,
  stepperRecipe,
  switchRecipe,
  tagRecipe,
  textareaRecipe,
  textRecipe,
} from './Recipes';

const themeExtension: ThemeExtender = {
  direction: 'rtl',
  components: {
    Button: buttonRecipe,
    Alert: alertRecipe,
    Text: textRecipe,
    Card: cardRecipe,
    Checkbox: checkboxRecipe,
    Radio: radioRecipe,
    Switch: switchRecipe,
    Input: inputRecipe,
    FormLabel: formLabelRecipe,
    FormError: formErrorRecipe,
    Select: selectRecipe,
    Textarea: textareaRecipe,
    Modal: modalRecipe,
    Container: containerRecipe,
    Link: linkRecipe,
    Tag: tagRecipe,
    Stepper: stepperRecipe,
    Form: formRecipe,
  },
  config: { cssVarPrefix: 'ck', initialColorMode: 'light' },
  fonts: { heading: 'inherit', body: 'inherit' },
  fontSizes: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '26px',
    '4xl': '40px',
  },
  styles: {
    global: {
      'html, body': { backgroundColor: 'white' },
      '*': { WebkitTapHighlightColor: 'transparent' },
      '.rocketchat-widget': {
        zIndex: { base: 'dropdown !important', md: 'sticky !important' },
      },
    },
  },
  colors: {
    primary: {
      900: '#6F1733',
      800: '#861C3E',
      700: '#A2224B',
      600: '#C4295A',
      500: '#EE316D',
      400: '#F36A95',
      300: '#F798B6',
      200: '#FAC5D6',
      100: '#FAE5ED',
      50: '#FAF0F3',
    },
    secondary: {
      900: '#076D49',
      800: '#0E6144',
      700: '#117652',
      600: '#158F63',
      500: '#19AD78',
      400: '#4ABF95',
      300: '#7BD0B1',
      200: '#ACE1CE',
      100: '#C5EADC',
      50: '#DDF2EA',
    },
    tertiary: {
      900: '#442E55',
      800: '#523867',
      700: '#63447C',
      600: '#785296',
      500: '#9163B5',
      400: '#A279C2',
      300: '#B38ECF',
      200: '#C5A5DD',
      100: '#DFC9F0',
      50: '#F2E5FC',
    },
    gray: {
      900: '#202124',
      800: '#393C40',
      700: '#717171',
      600: '#B3B3B3',
      500: '#CCCCCC',
      400: '#DDDDDD',
      300: '#EBEBEB',
      200: '#F2F2F2',
      150: '#F4F4F4',
      100: '#F8F8F8',
      50: '#FFFFFF',
    },
    anniversary: {
      wedding: '#930001',
      menBirthday: '#1a4ead',
      womenBirthday: '#b70049',
    },
    amber: { 300: '#F9F1FA', 500: '#FFEC60' },
    lapis: { 300: '#FBF7ED', 500: '#3C5ECB' },
    emerald: { 300: '#FFEFF4', 500: '#048A5E' },
    turquoise: { 300: '#FBF7ED', 500: '#008EFF' },
    ruby: { 300: '#E9F7FF', 500: '#C62F15' },
    diamond: { 300: '#F1FFED', 500: '#A13CCB' },
    opal: { 300: '#FBFAE8', 500: '#4C3073' },
    palePurple: { 500: '#F1E5F6' },
    zomp: { 500: '#58AA8C' },
    veronica: { 500: '#9747FF' },
    cambridgeBlue: { 500: '#88A59A' },
    grayAlpha: {
      100: 'rgba(204, 204, 204, 0.45)',
      200: '#F6F6F6',
      300: 'rgba(242, 242, 242, 0.69)',
      400: '#F8F8F8C4',
    },
    yellow: {
      100: '#FFEDDF',
      150: '#F9F3ED',
      200: '#FFECCF',
      250: '#F9F0BE',
      300: '#F8E8D3',
      400: '#EBD7EC',
      500: '#EFA22E',
      600: '#EBDBCC',
      700: '#FFF9FC',
      800: '#FFE7D3',
      900: '#FF9B21',
      910: '#EFCE00',
    },
    orange: { 400: '#FF6B6B', 500: '#FB6B4A' },
    blue: { 100: '#EBF6F6', 200: '#E5EEF8', 300: '#E8EEFF', 500: '#2357DE' },
    green: { 100: '#E0FAD7' },
    pink: { 200: '#FDDDE7', 300: '#FFDFE9', 400: '#FFE1F3', 500: '#EC97B5' },
    text: '#202124',
    error: { 300: '#FBD9D9', 400: '#FBD9D9', 500: '#E60000', 600: '#CC0000' },
    info: { 300: '#DCE6F7', 500: '#155BCB' },
    loading: { 300: '#DDF2EA', 500: '#168821' },
    warning: { 300: '#FFF7DA', 500: '#FFCD07' },
    success: { 300: '#DDF2EA', 500: '#168821' },
  },
  radii: {
    '3xs': '3px',
    '2xs': '6px',
    xs: '8px',
    sm: '9px',
    md: '11px',
    lg: '15px',
    xl: '1rem',
    '2xl': '1.125rem',
    '3xl': '1.5rem',
    '4xl': '2rem',
    '5xl': '2.5rem',
    curved: '222px',
    round: '50%',
  },
  shadows: {
    outline: 'none',
    xs: '0 4px 4px 0 rgba(0, 0, 0, 0.10)',
    sm: '0 4px 8px -1px rgba(0, 0, 0, 0.2)',
    md: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    lg: '0px 0px 11px 0px rgba(0, 0, 0, 0.12)',
    elevated1: '0 1px 4px 0 rgba(0, 0, 0, 0.33)',
    elevated2: '0 4px 8px 0 rgba(0, 0, 0, 0.25)',
    elevated3: '0 4px 8px 0 rgba(0, 0, 0, 0.12)',
  },
  zIndices: { onTop: 100 },
};

const theme = extendTheme(themeExtension);

export { fonts, theme };
