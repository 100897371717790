import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys
);

export const cardRecipe = defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'lg',
    },
  },
  variants: {
    elevated: {
      container: {
        shadow: 'md',
      },
    },
  },
});
