import type { IErrorResponse, ProfileResponse } from '@/types';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { urls } from '@/statics';

const useProfile = (): UseQueryResult<ProfileResponse, IErrorResponse> => {
  return useQuery({
    queryKey: [urls.profile],
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

export default useProfile;
