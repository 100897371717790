import type { IErrorResponse, ListResponse, ProductGroup } from '@/types';

import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { urls } from '@/statics';

const useProductGroupsByCode = ({
  codes,
}: {
  codes: number[];
}): UseQueryResult<ListResponse<ProductGroup>, IErrorResponse> =>
  useQuery({
    queryKey: [`${urls.productGroups}?codes=${codes.join(',')}`],
    enabled: false,
  });

export default useProductGroupsByCode;
