'use client';
import type { HelloBars } from '@/types';

import { Box, Link, Text } from '@/components';

const HelloBar: React.FC<{ data: HelloBars }> = ({ data }) => {
  const BoxHelloBar = () => {
    return (
      <>
        <Box
          width="100%"
          background="palePurple.500"
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="20px"
        >
          <Text fontSize={{ base: 'xs', xl: 'md' }} fontWeight="500">
            {data.title}
          </Text>
        </Box>
      </>
    );
  };
  return (
    <>
      {data.link ? (
        <Link href={data.link} target="_blank">
          <BoxHelloBar />
        </Link>
      ) : (
        <BoxHelloBar />
      )}
    </>
  );
};
export default HelloBar;
