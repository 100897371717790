import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const buttonRecipe = defineStyleConfig({
  baseStyle: {
    borderRadius: 'curved',
    fontWeight: '400',
    borderStyle: 'solid',
    w: 'max',
  },
  sizes: {
    sm: {
      h: '40px',
      px: '4',
      py: '1.5',
      fontSize: 'sm',
      borderWidth: '2px',
    },
    md: {
      h: '44px',
      px: '35px',
      py: '3',
      fontSize: 'md',
      borderWidth: '2px',
    },
    lg: {
      h: '48px',
      px: '35px',
      py: '3',
      fontSize: 'lg',
      borderWidth: '2px',
    },
    xl: {
      h: '60px',
      px: '44px',
      py: '4',
      fontSize: '20px',
    },
    xxl: {
      h: '75px',
      px: '35px',
      py: '12px',
      fontSize: 'xl',
      borderWidth: '2px',
    },
  },
  variants: {
    solid: {
      color: 'white',
      border: 'none',
    },
    unstyled: defineStyle(() => ({
      paddingInline: 4,
      lineHeight: 1.2,
      border: 'none',
    })),
    ghost: defineStyle(({ colorScheme }) => ({
      border: 'none',
      _hover: { bg: `${colorScheme}.100` },
      _active: { bg: `${colorScheme}.200` },
    })),
    outline: defineStyle(({ colorScheme }) =>
      colorScheme === 'gray'
        ? {
            color: `${colorScheme}.800`,
            borderColor: `${colorScheme}.800`,
            _hover: {
              borderColor: `${colorScheme}.800`,
              color: `${colorScheme}.800`,
              bg: `${colorScheme}.300`,
            },
            _active: {
              borderColor: 'transparent',
              color: 'white',
              bg: `${colorScheme}.700`,
            },
          }
        : {
            color: `${colorScheme}.600`,
            borderColor: `${colorScheme}.600`,
            _hover: {
              borderColor: `${colorScheme}.600`,
              color: `${colorScheme}.600`,
              bg: `${colorScheme}.50`,
            },
            _active: {
              borderColor: 'transparent',
              color: 'white',
              bg: `${colorScheme}.600`,
            },
          }
    ),
    link: defineStyle(({ colorScheme }) => ({
      textDecor: 'underline',
      textUnderlineOffset: 4,
      border: 'none',
      _hover: {
        color: `${colorScheme}.600`,
      },
    })),
  },
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'primary',
  },
});
