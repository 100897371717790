import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

export const switchRecipe = defineMultiStyleConfig({
  baseStyle: definePartsStyle(({ colorScheme }) => {
    return {
      container: {},
      thumb: {
        background: 'gray.500',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '10px',
        backgroundImage:
          '/**/url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB2aWV3Qm94PScwIDAgMTAwIDEwMCcgZmlsbD0nIzM5MzkzOSc+PHBhdGggZD0nTTY1LjEsNDkuOWwyNi42LTI2LjZjNC4yLTQuMiw0LjItMTEsMC0xNS4yaDBjLTQuMi00LjItMTEtNC4yLTE1LjIsMEw0OS45LDM0LjdMMjMuNCw4LjJjLTQuMi00LjItMTEtNC4yLTE1LjIsMHYwIGMtNC4yLDQuMi00LjIsMTEsMCwxNS4ybDI2LjYsMjYuNkw4LjIsNzYuNWMtNC4yLDQuMi00LjIsMTEsMCwxNS4yYzQuMiw0LjIsMTEsNC4yLDE1LjIsMGwyNi42LTI2LjZsMjYuNiwyNi42IGM0LjIsNC4yLDExLDQuMiwxNS4yLDBjNC4yLTQuMiw0LjItMTEsMC0xNS4yTDY1LjEsNDkuOXonLz48L3N2Zz4K")',
        _checked: {
          background: `${colorScheme}.500`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage:
            '/**/url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PSc5JyB2aWV3Qm94PScwIDAgMTAgOScgZmlsbD0nbm9uZSc+PHBhdGggZD0nTTMuNzI5ODggNi42MzM1NUwxLjg3NTAxIDUuMTQ0MjRDMS42MTM5IDQuOTM0NTkgMS4yMzU3OCA0Ljk3OTY0IDEuMDI5NjEgNS4yNDM4OUMwLjgyNDExNCA1LjUwNzI4IDAuODY3NDg1IDUuODg4NTYgMS4xMjc3MyA2LjA5NzUxTDMuNDU3MzMgNy45NjhDMy43MTg0NCA4LjE3NzY0IDQuMDk2NTcgOC4xMzI2IDQuMzAyNzMgNy44NjgzNUw4Ljk3MDM5IDEuODg1NjNDOS4xNzU4OSAxLjYyMjIzIDkuMTMyNTEgMS4yNDA5NiA4Ljg3MjI3IDEuMDMyQzguNjExMTggMC44MjIzNjYgOC4yMzMwOCAwLjg2NzM5OCA4LjAyNjkxIDEuMTMxNjFDOC4wMjY5IDEuMTMxNjIgOC4wMjY4OSAxLjEzMTY0IDguMDI2ODggMS4xMzE2NUwzLjcyOTg4IDYuNjMzNTVaJyBmaWxsPSd3aGl0ZScgc3Ryb2tlPSd3aGl0ZScgc3Ryb2tlLXdpZHRoPScwLjInLz48L3N2Zz4K")',
        },
      },
      track: {
        p: 1,
        borderColor: 'gray.500',
        borderWidth: 0.25,
        background: 'white',
        _disabled: {
          _active: {
            background: 'white',
          },
        },
        _active: {
          background: 'grayAlpha.100',
        },
        _checked: {
          _disabled: {
            _active: {
              background: 'white',
            },
          },
          _active: {
            background: `${colorScheme}.300`,
          },
        },
      },
    };
  }),
  variants: {},
  sizes: {
    lg: {
      thumb: {
        h: '20px',
        w: '20px',
      },
      track: {
        h: '20px',
        w: '42px',
      },
    },
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'lg',
  },
});
