type LocalStorageItem = {
  name: string;
  value: string;
};

const setLocalStorage = ({ name, value }: LocalStorageItem): void => {
  localStorage.setItem(name, value);
};

const getLocalStorage = ({
  name,
}: Pick<LocalStorageItem, 'name'>): string | null => {
  return localStorage.getItem(name);
};

const deleteLocalStorage = ({ name }: Pick<LocalStorageItem, 'name'>): void => {
  localStorage.removeItem(name);
};

export { deleteLocalStorage, getLocalStorage, setLocalStorage };
